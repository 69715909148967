
@import './config.scss';

html, body { overflow-x: hidden; }

@media (max-width: $break-point) {
   html {
      font-size: 13px;
   }
}
@media (min-width: $break-point) and (max-width: 1300px) {
   html {
      font-size: 14px;
   }
}

body { 
   background-color: #fff;
   color: $color-primary;
   font-family: $font-primary;
   
   .div-wrap {
      max-width: 1100px;
      padding: 4rem 1rem;
      margin: auto;
   }
   button {
      display: flex; align-items: center; justify-content: center; gap: 1rem;
      height: 3.5rem;
      padding: 0.1em 1em;
      min-width: 200px;

      cursor: pointer;
      background-color: $color-accent;
      border-radius: 8px;
      color: white;
      font-family: $font-secondary;
      font-weight: 800;
      box-shadow: 0px 8px 20px rgba($color-accent, .3);

      img {
         filter: invert(1) brightness(2);
         height: 60%;
      }
      span {
         padding-bottom: .1em;
      }
   }
   h1,h2 {
      font-size: 2.8rem;
      font-family: $font-secondary;
      font-weight: 800;
      line-height: 1.25;
      padding-bottom: 2rem;
      white-space: pre-line;

      em {
         font-style: normal;
         color: $color-accent;
      }
   }

   $menu-width:320px;
   @mixin menu-pos {
      display: none;
      position: fixed; top: 2rem; right: 1rem;
      z-index: 5;
      height: 3rem; width: 3rem;
      @media (max-width: $break-point) { display: block; }
   }
   .menu-open {
      @include menu-pos;
      opacity: 0;
      z-index: 6;
      cursor: pointer;

      &:checked ~ .menu {
         transform: translateX(0px);
      }

      &:checked ~ .menu-open-btn {
         span {
            &:nth-child(1){
               transform: rotate(40deg) translateY(.5rem);
            }
            &:nth-child(2){
               opacity: 0;
            }
            &:nth-child(3){
               transform: rotate(-40deg) translateY(-.5rem);
            }
         }
      }
      &:checked ~ .menu-open-btn div {
         transform: translateX(25%);
      }
   }
   .menu-open-btn {
      @include menu-pos;
      padding: 1rem .75rem;
      background-color: #fff;
      
      div {
         transform: translateX(0%);
         height: 100%;
         display: flex; flex-direction: column; justify-content: space-between;
      }
      span {
         display: block;
         height: 3px;
         width: 100%;

         background-color: $color-primary;
         border-radius: 99px;
         transition: all .5s;
      }

   }
   .menu {
      position: fixed; top: 0; right: 0;
      display: flex; flex-direction: column; justify-content: space-between;
      height: 100vh; width: $menu-width;
      z-index: 4;
      padding: 6rem 4rem;
      transform: translateX($menu-width);
      
      color: #fff;
      background-color: rgba($color-primary, .85);
      backdrop-filter: blur(8px);
      transition: all .3s;

      ul {
         li {
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 1rem;
         }
      }

      .links {
         .social-links {
            display: flex; gap: 1rem;

            img {
               height: 1.5rem;
               filter: saturate(0) invert(1);
            }
         }
      }
      .legal {
         margin-block: 1rem;
         a {
            text-decoration: underline;
         }
      }
      .copy {
      }
   }

   nav {
      position: relative;
      padding: 2rem 0rem 2rem 1rem !important;
      display: flex; align-items: center; justify-content: space-between;

      img.logo {
         max-height: 2.8rem;
         transform: translateY(15%);
      }

      ul {
         position: absolute; top: 50%; left: 50%; 
         transform: translate(-50%,-50%);
         display: flex;
         gap: 2rem;
         font-size: .9rem;
         font-weight: 600;
         text-transform: capitalize;

         li {
            opacity: .6;
         }
         li.active {
            opacity: 1 !important;
         }
      }

      button {
         transform: scale(.8);
      }

   }

   .overlay {
      position: fixed; top: 0; left: 0;
      height: 100%; width: 100%;
      z-index: 99;

      background-color: rgba(#000, .5);
      backdrop-filter: blur(8px);
      opacity: 1;
      transition: opacity .5s ease-in;

      .embeded-vid {
         position: relative; 
         display: flex;
         height: 100%; width: 100%;

         iframe {
            margin: auto;
            min-height: 443px;
            max-width: 95%;
            border-radius: 8px;
         }
      }
   }
   .hide-overlay {
      opacity: 0;
      pointer-events: none;
   }

   main {
      
      .banner {
         &-rev {
            
            .text {
               margin-left: auto !important;

            }
   
            .graphic {
               right: initial; left: 0;
               transform: translateX(-35%) !important;
               
               @media (min-width: $break-point) and (max-width: 1400px) {
                  transform: translateX(-10%) !important;
               }
            }

         }
         position: relative;
         min-height: 37.5rem; 

         .text {
            margin: auto 0;
            max-width: 34rem;
            @media (max-width: $break-point) {
               margin: 2rem auto !important;
            }
         }

         .graphic {
            display: table;
            position: absolute; top: 0; right: 0;
            height: 100%;
            transform: translateX(35%);
            z-index: -1;
            @media (min-width: $break-point) and (max-width: 1300px) {
               transform: translateX(10%);
            }

            
            img {
               height: 100%;
               max-height: 30rem; 
            }
         }

         p {
            font-size: 1.25rem;
            line-height: 1.5;
            opacity: .7;
            padding-bottom: 2rem;
         }

         .btns {
            display: flex; gap: 1rem;

            button:nth-child(2) {
               background-color: transparent;
               color: $color-primary;
               box-shadow: initial;

               img {
                  filter: initial;
                  margin-right: -8px;
               }
            }
         }

      }
      
      .brands {
         .div-wrap {padding: initial;}
         border-top: 1.5px solid rgba(#eee, .5);
         &>div {
            display: flex; justify-content: space-between;
            // overflow-x: hidden;

            img {
               height: 15vh;
            }
         }
      }

      .features {
         background-color: $color-background;
         margin-bottom: 5rem;
         
         .text {
            text-align: center;
            
            p {
               // margin-bottom: 1rem;

               max-width: 34rem;
               margin: auto;

               font-size: 1.25rem;
               line-height: 1.5;
               opacity: .7;
               padding-bottom: 2rem;
            }

         }
         .slides {
            .card {
               padding: 3rem 2rem;

               background-color: #fff;
               border-radius: 8px;
               box-shadow: 0px 8px 20px rgba(rgb(93, 118, 168), .1);

               img {
                  padding: .75rem;
                  border: 1px solid #eee;
                  border-radius: 8px;
               }
               h3 {
                  padding-block: 1rem;

                  font-size: 1.5rem;
                  font-family: $font-secondary;
                  font-weight: 800;
               }
               p {
                  line-height: 1.8;
                  opacity: .7;
                  max-width: 30ch;
               }

               p.date {
                  font-size: .9em;
               }
               
               .chips span {
                  display: inline-block;
                  padding: .5em 1em;
                  margin-top: 1rem;
                  
                  font-size: .8em;
                  font-weight: 800;
                  color: $color-accent;
                  border: 1px solid #eee;
                  border-radius: 3px;
               }
               
            }
            
         }

         button {
            margin: 3rem auto 0 auto;
         }

      }
      .blogs {
         margin-bottom: 0px;
      }

      .stripe {
         background-color: $color-accent;
         color: #fff;

         .optin-form { display: flex; justify-content: space-between; align-items: center;}

         ul {
            display: flex; justify-content: space-between;

            div {
               text-align: center;
               &:first-child{
                  font-size: 3rem;
                  font-weight: 800;
               }
               &:last-child{
                  font-weight: 600;
                  opacity: .9;
               }
            }
         }

         h2 {
            font-size: 2.4rem;
         }
         p {
            font-size: 1.2rem;
            line-height: 1.7;
            max-width: 45ch;
         }

         .email {
            flex: 1;
         }
         form {
            display: flex;
            overflow: hidden;
            margin-left: auto;
            width: fit-content;
            border-radius: 8px;
            background-color: #C9DCEC;
            min-width: 400px;
            
            input {
               padding: 1rem 2rem;
               color: $color-primary;
            }
            input[type=email] {
               flex: 1;
            }
            input[type=submit] {
               cursor: pointer;
               background-color: #fff;
               border-radius: 8px;
               font-weight: 600;
            }
         }
      }

      .reviews {

         &>div{position: relative;}
         .btns {
            display: flex;
            width: fit-content;
            margin-left: auto;
            transform: translateY(-100%);

            button {
               background-color: $color-background;
               min-width: 100px;
               margin-left: 1rem;
               box-shadow: initial;

               img {
                  opacity: .8;
               }
            }
         }

         .swiper-button-disabled {
            opacity: .5 !important;
         }
         .slider, .swiper {
            overflow: visible !important;
         }
         .slider {
            .slider-slide {
               width: 100% !important; max-width: 100% !important;
            }

            .card {
               position: relative;
               padding: 3rem 2rem;

               background-color: #fff;
               border-radius: 8px;
               box-shadow: 0px 8px 20px rgba(rgb(93, 118, 168), .2);

               &:last-child {
                  // padding-right: 100px !important;
               }
               
               img {
                  height: 5rem; width: 5rem;
                  position: absolute; top: 0; left: 2rem;
                  transform: translateY(-50%);

                  border-radius: 50%;
                  box-shadow: 0px 8px 20px rgba(rgb(0, 0, 0), .05);
               }

               blockquote {
                  margin-block: 2rem;
                  opacity: .8;
                  line-height: 1.8;

                  &::after, &::before {
                     content: "\"";
                  }
               }

               .author {
                  div {
                     &:first-child{
                        font-weight: 800;
                     }
                     &:last-child{
                        font-size: .9rem;
                        opacity: .8;
                     }
                  }
               }
            }
         }

         .swiper-slide:last-child {
            flex: 50px !important;
            width: 50px !important;
         }
      }
      
      .contact-us {
         background-color: $color-background;
         
         &>div{ 
            display: flex; 

            &>div { flex: 1; }
         }
         
         .text {
            display: flex; flex-direction: column; min-height: 100%;
            h2 {
               margin-top: 1rem;

               font-size: 2.4rem;
               max-width: 24ch;
               line-height: 1.5;
            }
            p {
               opacity: .8;
               max-width: 45ch;
            }

            ul {
               margin-block: auto 1rem;

               li {
                  margin-bottom: 2rem;
                  span {
                     display: block;
                     margin-bottom: .5em;

                     &:first-child {
                        opacity: .8;
                     }
                     &:last-child {
                        font-weight: 800;
                     }
                  }
               }
            }
         }

         form {
            margin-left: auto;
            padding: 4rem;
            width: fit-content;
            box-shadow: 0px 12px 20px rgba($color-accent, .1);

            border-radius: 8px;
            background-color: #fff;

            h3 {
               margin-bottom: 4vh;
               font-size: 1.8rem;
               font-weight: 800;
            }
            
            span {
               display: block;
               position: relative;
               margin-bottom: 2rem;

               label {
                  position: absolute; top: 80%; left:.2rem;
                  transform: translateY(-100%);
                  font-size: .9rem;
                  transform-origin: 0% 0%;
                  transform: scale(1);
                  transition: all .3s ease-out;
               }
               input, textarea {
                  position: relative;
                  display: block;
                  min-width: 320px;
                  padding: .5rem;
                  resize: none;
                  
                  font-size: .9rem;
                  color: $color-primary;
                  border-bottom: 1px solid #ccc;
                  
                  &:focus, &:valid {
                     border-bottom: 1px solid $color-accent;
                     ~label {
                        top: -25%;
                        transform: scale(.7);
                        color: $color-accent;

                     }
                  }
               }
            }
            button {
               margin-top: 8vh;
               width: 100%;
            }
         }

         p:first-of-type { display: none; }

         .small-form {
            display: none;
            width: 100%;
         }
      }

   }


   footer {
      background-color: $color-background;
      font-size: .85rem;

      hr {
         border: .5px solid #ddd;
         margin-block: 3rem;
      }
      
      h4 {
         font-size: 1rem;
         font-family: $font-secondary;
         font-weight: 800;
         padding-bottom: 1rem;
      }

      a {
         opacity: .7;
         &:hover {
            opacity: 1;
         }
      }
      a>img {
         display: inline-block;
         position: relative; top: 0.4em;
         filter: brightness(.2);
      }

      &>div>div {
         position: relative;
         display: flex; justify-content: space-between;

         .logo img{
            display: block;
            height: 3rem;
         }
         .links {
            min-width: 420px;
            display: flex; justify-content: space-between;
            li {padding-bottom: 1rem;}
         }
         .legal,.info {
            display: flex; gap: 5vw;
         }
         .info a {
            color: $color-accent;
            font-weight: 600;
            opacity: .8;
         }
         .social .icons {
            display: flex; gap: 1rem;

            img {
               height: 1.5rem;
               filter: saturate(.3);
               opacity: .7;
            }
         }

      }

      .accord {
         display: none;
         margin-bottom: 2rem;

         .entry {
            border-bottom: 1px solid #333;
            position: relative;
            padding-block: 1rem;
            
            input {
               position: absolute; top: 0; left: 0;
               width: 100%;
               height: 100%;
               opacity: 0;
               
               &:checked {
                  ~ .head img {
                     transform: rotate(180deg);
                  }

                  ~ .body {
                     max-height: 100vh;
                  }
               }
            }
            
            .head {
               display: flex; justify-content: space-between; align-items: center;
               img {
                  transform: rotate(0deg);
                  max-height: 2rem;
               }
            }

            .body {
               max-height: 0vh;
               overflow: hidden;
               transition: all .3s;

               li {
                  margin-bottom: 1rem;
               }
            }
         }
      }
   }


   @media (max-width: $break-point) {

      nav {
         ul.links, button { display: none; }
      }

      main {
         .div-wrap {
            padding: 4rem 1rem;
         }
           
         .banner {
            display: block;
            .graphic {
               position: static;
               display: flex;
               transform: initial !important;

               img {
                  margin: auto;
               }
            }

            .btns {
               flex-direction: column;

               button:last-child {
                  border: 2px solid $color-primary;
                  opacity: .8;
               }
            }
            button {
               width: 100%;
            }
         }

         .brands {
            overflow-x: scroll;
         }

         .features {
            padding: 3vh 1rem;
            
            button {
               width: 100%;
            }
         }

         .stripe {
            text-align: center;
            .optin-form, ul {
               flex-direction: column; gap: 2rem;
            }

            li {
               margin: 0 4rem;
               padding-bottom: 2rem;
               border-bottom: 1px solid rgba(#fff, .3);

               &:last-child {
                  padding-bottom: 0px;
                  border-bottom: none;
               }
            }
         }

         .reviews {
            .btns {
               transform: translateY(-50%) scale(.75);
            }

         }

         .contact-us {
            &>div{ 
               flex-direction: column;
            }
            form {
               display: none;
            }
            .small-form {
               display: block;
            }
         }

         .optin-form .email form{
            flex-direction: column; gap: 2rem;
            background-color: transparent;

            input[type=email] {
               border-radius: 8px;
               border: 2px solid rgba(#fff, .5);
               color: #fff;
            }
         }
         
      }
      ::placeholder {
         color: #fff;
         opacity: .75;
      }
      
      footer {

         hr {
            display: none;
         }

         .logo {
            transform-origin: 0% 0%;
            transform: translateY(-50%) scale(1.5);
         }

         .copy {
            margin-top: .5rem;
         }

         .contact, .links {
            display: none !important;
         }

         &>div>div {
            flex-direction: column; 

            .social { 
               display: flex; align-items: center; justify-content: space-between;
               margin-bottom: 1rem;

               h4 { padding-bottom: 0px;  }
               .icons img { filter: initial; opacity: initial; }
            }
         }

         .legal {
            border-bottom: 1px solid #444;
            padding-bottom: 1rem;
         }
         .copy { padding-top: .5rem; }

         .accord {
            display: block;
         }
      }
   }

}